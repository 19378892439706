import React from "react"
import { graphql, Link } from "gatsby"
import { bodyCopy } from "../../utils/styles"
import ReadMore from "../read-more"

const linkCss = theme => ({
  textDecoration: `none`,
  "&:hover [data-name='preview-heading']": {
    color: theme.colors.gatsby,
  },
  // TODO: this looks like it could be a potential a11y problem
  "&:hover .read-more": {
    color: theme.colors.gatsby,
    borderBottom: `1px solid transparent`,
  },
})

const customTitleCss = theme => ({
  fontWeight: `bold`,
  fontSize: theme.fontSizes[5],
  marginBottom: theme.space[3],
  [theme.mediaQueries.desktop]: {
    fontSize: theme.fontSizes[7],
    maxWidth: 480,
    marginBottom: theme.space[4],
  },
})

const paragraphCss = theme => ({
  marginBottom: theme.space[3],
})

const fontSizeCss = theme => ({
  fontSize: theme.fontSizes[1],
})

const articleCardCss = theme => ({
  marginBottom: theme.space[4],
})

const BlogPostPreviewItem = ({ post, Heading = `h3` }) => {
  return (
    <article css={{ articleCardCss }} key={post.slug}>
      <Link to={`/blog/${post.slug}`} css={linkCss}>
        <Heading data-name="preview-heading" css={customTitleCss}>
          {post.title}
        </Heading>
        <div css={bodyCopy}>
          <p css={theme => [paragraphCss(theme), fontSizeCss(theme)]}>
            By {post.author?.node?.name || `Gatsby Contributor`} on {post.date}
          </p>
          <p css={paragraphCss}>{post.rawExcerpt?.text}</p>
          <ReadMore />
        </div>
      </Link>
    </article>
  )
}

export default BlogPostPreviewItem

export const BlogPostPreviewInformationFragment = graphql`
  fragment BlogPostPreviewInformation on WpPost {
    id
    title
    date(formatString: "MMMM Do, YYYY")
    slug
    author {
      node {
        name
      }
    }
    rawExcerpt {
      text
    }
  }
`
